import React from "react";

const Navbar = () => {
  return (
    <div>
      <div>
        <h1>Navbar</h1>
      </div>
    </div>
  );
};

export default Navbar;
