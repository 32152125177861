import React from "react";

const Item = ({ item }) => {
  return (
    <div>
      <span style={item.packed ? { textDecoration: "line-through" } : {}}>
        {" "}
        {item.quantity} {item.description}
      </span>
      <button>X</button>
    </div>
  );
};

export default Item;
