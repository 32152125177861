import React from "react";
import Navbar from "./components/Navbar";
import Form from "./components/Form";
import PackingList from "./components/PackingList";
import Stats from "./components/Stats";

const App = () => {
  return (
    <div className="app">
      <Navbar />
      <Form />
      <PackingList />
      <Stats />
    </div>
  );
};

export default App;
