import React from "react";

const Stats = () => {
  return (
    <div>
      <footer className="stats">
        <em>You have X items on your list, and you already packed X (X%)</em>
      </footer>
    </div>
  );
};

export default Stats;
